<template>
  <div class="page1">
    <!--面包屑-->
    <div class="breadcrumbAddRoadside"></div>
    <!--主体内容-->
    <div class="content">
      <h2 class="title">
        <div class="title_icon"></div>
        <!-- {{isEdit ? '修改' : '添加'}}收费员 -->
        {{ $t('searchModule.Basic_Information') }}
      </h2>
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="submitData">
        <el-form
          label-position="right"
          label-width="110px"
          :model="formInline"
          style="width: 330px"
          :rules="rules"
          ref="form"
        >
          <el-form-item :label="$t('searchModule.full_name')" prop="mcUserName">
            <el-input
              :maxlength="10"
              v-model.trim="formInline.mcUserName"
              :placeholder="$t('placeholder.please_enter_name')"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.sex')" prop="sex">
            <el-radio-group
              v-model.trim="formInline.sex"
              filterable
            >
              <el-radio :label="1">{{ $t('searchModule.male') }}</el-radio>
              <el-radio :label="0">{{ $t('searchModule.female') }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="$t('searchModule.account_number')" prop="account">
            <el-input
              :maxlength="20"
              :disabled="isEdit"
              v-model.trim="formInline.account"
              :placeholder="$t('placeholder.please_enter_account')"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.phone_number')" prop="mobile">
            <el-input
              :maxlength="11"
              v-model.trim="formInline.mobile"
              :placeholder="$t('placeholder.Please_enter_your_phone_number')"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Department_to')" prop="departmentId">
            <el-cascader
              :show-all-levels="false"
              style="width: 220px"
              :options="options"
              v-model="formInline.departmentId"
              :props="{
                emitPath: false,
                checkStrictly: true,
                value: 'deptId',
                label: 'deptName',
                children: 'childNodes',
              }"
              clearable
            ></el-cascader>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Merchant_Name')" prop="operationId">
            <el-select
              @change="queryParkList(1)"
              v-model.trim="formInline.operationId"
              filterable
              size="15"
              :disabled="isEdit"
              :placeholder="$t('placeholder.please_choose')"
            >
              <!-- <el-option label="全部" value=""></el-option> -->
              <el-option
                :label="value.operationName"
                :value="value.operationId"
                :key="value.operationId"
                v-for="value in roleListName"
              ></el-option>
            </el-select>
          </el-form-item>
          <h2 class="title">
            <div class="title_icon"></div>
            {{ $t('searchModule.Choose_parking_lot') }}
          </h2>
          <el-form-item
            class="parkc"
            :label="$t('searchModule.Choose_parking_lot')"
            style="width: 705px"
            prop="parkIds"
          >
            <el-transfer
              target-order="push"
              :titles="[$t('searchModule.Not_selected'), $t('searchModule.Selected')]"
              filterable
              :props="{
                key: 'parkId',
                label: 'parkName',
              }"
              :filter-placeholder="$t('placeholder.Please_enter_the_pinyin_of_the_city')"
              v-model="selected"
              :data="parkList"
            >
            </el-transfer>
          </el-form-item>
        </el-form>
        <div style="text-align: left; margin-left: 8.5%; margin-top: 40px">
          <el-button type="primary" @click="submitData" style="width: 88px"
            >{{ $t('button.submit') }}</el-button
          >
          <el-button type="" @click="$router.go(-1)" style="width: 88px"
            >{{ $t('button.cancel') }}</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
// import //  dateFormat
// "@/common/js/public.js";
export default {
  name: "tollgathererManageAdd",
  data() {
    let checkCode = (rule, value, callback) => {
      if (/[\u4E00-\u9FA5]/.test(value)) {
        return callback(new Error(this.$t('error_message.Employee_ID_cannot_contain_Chinese_characters')));
      }
      if (value) {
        if (value.length < 3 || value.length > 16) {
          return callback(new Error(this.$t('error_message.The_length_of_the_employee_ID_should_be_within_3_16_characters')));
        }
        if (/[a-zA-Z0-9]{2,50}/.test(value)) {
          callback();
          // getDt(opt);
        } else {
          callback(new Error(this.$t('error_message.Employee_ID_includes_letters_and_numbers')));
        }
      } else {
        callback(new Error(this.$t('error_message.The_employee_ID_cannot_be_empty')));
      }
    };
    let checkPDAccount = (rule, value, callback) => {
      if (value) {
        if (/\w{3,16}/.test(value) && !/^\d{3,16}$/.test(value)) {
          callback();
        } else {
          callback(new Error(this.$t('error_message.Account_3_16_characters_and_cannot_be_a_pure_number')));
        }
      } else {
        callback(new Error(this.$t('error_message.Account_cannot_be_empty')));
      }
    };
    let setBirth = (rule, value, callback) => {
      let cur = new Date().getFullYear();
      if (parseInt(value) != value) {
        callback(new Error(this.$t('error_message.The_format_of_the_year_of_birth_is_incorrect')));
      } else if (value > cur) {
        callback(new Error(this.$t('error_message.Birth_year_cannot_be_greater_than_the_current_year')));
      } else if (!/^\d{4}$/.test(value)) {
        callback(new Error(this.$t('error_message.Birth_year_must_be_a_4_digit_number')));
      } else {
        callback();
      }
    };
    let checkName = (rule, value, callback) => {
      let len = value.length;
      for (let i = 0; i < len; i++) {
        if (value.charCodeAt(i) < 10000) {
          callback(new Error(this.$t('error_message.The_name_must_be_in_Chinese_characters')));
          return;
        }
      }
      callback();
    };
    let checkPhone = (rule, value, callback) => {
      if (!/^\d{11}$/.test(value)) {
        callback(new Error(this.$t('error_message.The_format_of_the_phone_number_is_incorrect')));
      } else {
        callback();
      }
    };
    return {
      roleListName: [],
      options: [],
      selected: [],
      parkList: [],
      isEdit: false,
      formInline: {
        departmentId: "",
        mcUserName: "",
        sex: 1,
        mobile: "",
        account: "",
        parkIds: [],
        operationId: "",
      },
      rules: {
        mcUserName: [
          {
            required: true,
            message: this.$t('placeholder.please_enter_name'),
            trigger: "blur",
          },
          { validator: checkName, trigger: "blur" },
        ],
        sex: [
          {
            required: true,
            message: this.$t('placeholder.please_choose'),
            trigger: "blur",
          },
        ],
        role: [
          {
            required: true,
            message: this.$t('placeholder.please_choose'),
            trigger: "change",
          },
        ],
        departmentId: [
          {
            required: true,
            message: this.$t('placeholder.please_choose'),
            trigger: "change",
          },
        ],
        birthday: [
          {
            required: true,
            message: this.$t('placeholder.please_choose'),
            trigger: "blur",
          },
          { validator: setBirth, trigger: "blur" },
        ],

        mobile: [
          {
            required: true,
            message: this.$t('placeholder.Please_enter_your_phone_number'),
            trigger: "blur",
          },
          { validator: checkPhone, trigger: "blur" },
        ],
        account: [
          {
            required: true,
            message: this.$t('placeholder.please_enter_account'),
            trigger: "blur",
          },
          { validator: checkPDAccount, trigger: "blur" },
        ],

        operationId: [
          {
            required: true,
            message: this.$t('error_message.Cannot_be_empty'),
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    /* 商户名称的下拉框 运营商 */
    getRoleList() {
      this.$axios
        .get("/acb/2.0/operation/nameList", {
          data: {},
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.roleListName = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 查询车场列表
    queryParkList(tag) {
      let url = "/acb/2.0/bacb/systems/loginUser/getParkName";
      this.$axios
        .get(url, {
          data: {
            page: 0,
            pageSize: 0,
            slaveRelations: "0,1",
            operationId: this.formInline.operationId,
          },
        })
        .then((res) => {
          if (res.state != 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            return;
          }
          this.parkList = res.value;
          if (tag == 1) {
            this.selected = [];
          }
        });
    },
    // 获取部门
    getDeptTreeByCurrentUser() {
      this.$axios.get("/acb/2.0/dept/getByCurrentUser").then((res) => {
        if (res.state == 0) {
          this.options = [res.value];
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    addList() {
      let url = this.isEdit
        ? "/acb/2.0/bacb/mcUser/update"
        : "/acb/2.0/bacb/mcUser/add";
      let method = "post";
      this.$axios[method](url, {
        data: this.formInline,
      }).then((res) => {
        if (res.state != 0) {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return;
        }
        if (res.state == 0) {
          this.$router.go(-1);
          this.$message({ message: res.desc, type: "success" });
        }
      });
    },

    // 编辑赋值
    workDetail() {
      let url = "/acb/2.0/bacb/mcUser/getById";
      this.$axios
        .get(url, {
          data: {
            mcUserId: this.formInline.mcUserId,
          },
        })
        .then((res) => {
          if (res.state != 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            return;
          }
          for (let i in this.formInline) {
            // console.log("i==>", i);
            this.formInline[i] = res.value[i];
          }
          this.queryParkList(); // 获取车场
          this.selected = this.formInline["parkIds"];

          // this.formInline.role =  this.formInline.role + ""
        });
    },
    submitData() {
      this.formInline.parkIds = this.selected.join(",");
      this.$refs["form"].validate((valid) => {
        // console.log("");
        if (valid) {
          this.addList();
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
  },
  components: {},
  created() {
    this.getRoleList(); // 获取商户
    // console.log("this.$route.query.=>", this.$route.query);
    if (this.$route.query.mcUserId) {
      this.formInline.mcUserId = this.$route.query.mcUserId;
      this.isEdit = true;
      this.workDetail();
    } else {
    }
    this.getDeptTreeByCurrentUser(); // 获取部门
    // this.queryParkList(); // 获取车场
  },
  mounted() {},
  computed: {},
};
</script>
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
.parkc >>> .el-form-item__content {
  line-height: 1px !important;
}
.parkc >>> .el-input__inner {
  min-width: 172px !important;
  padding-left: 26px !important;
}
.parkc >>> .el-input {
  min-width: 172px !important;
}
.parkc >>> .el-input__prefix {
  left: 0 !important;
  top: 0 !important;
}
.parkc {
  height: initial !important;
}
</style>
  <style scoped lang="stylus" rel="stylesheet/stylus">
  .breadcrumbAddRoadside {
    height: 35px;
  }

  .content {
    background: #FFFFFF;
    overflow: hidden;
    border: 1px solid #C0CCDA;
    border-radius: 4px;
    padding: 20px;
  }
</style>
  <style scoped>
.chosePark {
  float: right;
  margin-right: -70px;
  margin-top: -52px;
}
</style>
